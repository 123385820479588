<template>
  <div class="crowdfunding">
    <div class="mask"></div>
    <div style="position: relative">
      <img class="img" :src="require('@/assets/logo_big.png')" />
      <p class="p">Crowdfunding is preparing, so please stay tuned.</p>
      <p class="progress ml-auto mr-auto">0%</p>
      <!-- <b-button variant="outline-primary">Coming soon</b-button> -->
      <h2 class="title">Coming soon...</h2>
    </div>
  </div>
</template>

<script>
export default {
  name: 'crowdfunding'
}
</script>

<style lang="scss" scoped>
.crowdfunding {
  position: relative;
  padding-top: 7rem;
  padding-bottom: 15.25rem;
  text-align: center;
  background: url("../assets/banner.png") no-repeat;
  color: #fff;

  .mask {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(8, 16, 22, 0.9);
  }

  .p {
    margin-top: 2.8rem;
    margin-bottom: 3.81rem;
    font-size: 24px;
  }

  .progress {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 722px;
    height: 22px;
    margin-bottom: 4.625rem;
    opacity: 1;
    background: #081016;
    border: 2px solid #ffac15;
    border-radius: 10px;
    text-align: center;
  }
}
</style>
